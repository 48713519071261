@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
body,
html {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.app {
  display: flex;
}
a {
  text-decoration: none !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  background: #ffffff !important;
}

html {
  --scrollbarBG: rgb(227, 226, 226);
  --thumbBG: #081225;
  --primary: #081225;
  --white: #ffffff;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

@font-face {
  font-family: "UniSans";
  src: url("./Assets/UniSans-Trial-Heavy.woff2") format("woff2");
}

.loginTitle {
  font-family: "UniSans" !important;
  color: #00009f !important;
}

/* utility css */
.primaryBtn {
  width: "30%";
  color: #fff !important;
  background: #081225 !important;
  border: 1px solid #081225 !important;
  border-radius: 20px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  outline: 1px solid #081225 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px!important;
}

.primaryBtn:disabled {
  background-color: #bbb9b9 !important;
  border: 1px solid #bbb9b9 !important;
  outline: 1px solid #bbb9b9 !important;
}

.primaryBtn:hover {
  color: #081225 !important;
  background: #f0f3f4 !important;
  border: 1px solid #081225 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}
.sessionLive {
  width: "30%";
  color: #fff !important;
  background: #c22121 !important;
  border: 1px solid #c22121 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  outline: 1px solid #c22121 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
}
.sessionLive:hover {
  color: #c22121 !important;
  background: #f0f3f4 !important;
  border: 1px solid #c22121 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}
.secondaryBtn {
  color: #081225 !important;
  background-color: #f0f3f4 !important;
  border-color: #081225 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  outline: 1px solid #081225 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
}
.secondaryBtn:hover {
  color: #fff !important;
  background-color: #081225 !important;
  border: 1px solid #081225 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}
.active {
  color: white;
}

.PageNotFound {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PageNotFound h1 {
  font-weight: 600;
}

.errMsg {
  font-size: 12px !important;
  color: red !important;
  margin: 2px 0px 5px 0px !important;
}

/*------------------- All Buttons -------------------*/
.btn-login {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  border-radius: 12px !important;
  border: 1px solid transparent !important;
  transition: 0.3s !important;
}

.btn-submit {
  background: linear-gradient(145deg, #000000, #000000) !important;
  padding: 4px 12px;
  border-radius: 12px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-logout {
  width: max-content !important;
  padding: 12px 10px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  border: none !important;
  margin: 0px 10px !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  color: #000000 !important;
  transition: 0.3s !important;
}

.btn-logout:hover {
  transform: translateX(-5px) !important;
}

nav {
  position: fixed;
  left: 40vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  z-index: 1000;
  border: 1px solid #ffffff;
  border-radius: 0px !important;
}

nav h5 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover {
  transform: translateY(-3px);
  color: white;
}

@media only screen and (max-width: 600px) {
  .outer-wrapper {
    padding: 0px !important;
    max-width: 100% !important;
  }
}

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 20%;
    z-index: 1000;
    flex-direction: column;
    padding: 20% 5% 20% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: var(--primary);
  }
  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #004686;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #004686;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }
  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: var(--white);
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: var(--white);
  }
}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */

@media (min-width: 992px) {
  .lg-nav {
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p {
    margin-bottom: 0px;
  }

  .mobile-nav {
    display: none;
  }
  .mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg {
    height: 92vh;
    border: none !important;
    background: #ffffff !important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: hidden !important;
    width: 40vh;
    /* scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(255, 255, 255);
    --thumbBG: #004686; */
    padding: 20px 8px 30px 0px !important;
  }

  .sidenav-lg .menus {
    overflow-y: auto;
    overflow-x: hidden;
    transition: overflow-y 0.5s ease;
    height: 72vh;
    padding: 8px;
  }

  .sidenav-lg .menus::-webkit-scrollbar {
    width: 8px;
  }

  .sidenav-lg .menus::-webkit-scrollbar-track {
    background: #073c7c;
  }

  .sidenav-lg .menus::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 6px;
    border: 3px solid #073c7c;
  }

  .sidenav-lg-parent {
    position: relative;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 0vh;
    right: 0vh;
    top: 10vh;
  }
}

.accordion {
  background-color: transparent !important;
}

.accordion .accordion-item {
  border: #081225;
}

.accordion .accrodion-body {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background: #f0f3f4 !important;
  border: none !important;
  padding: 0px !important;
  box-shadow: none;
  color: white !important;
  border-radius: 12px !important;
}

.accordion .activeMenu .accordion-button {
  width: 100% !important;
  padding: 12px 4px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  border: none !important;
  /* margin: 0px 10px !important; */
  border-radius: 12px !important;
  background-color: #081225 !important;
  color: white !important;
}
.accordion .menuItem .accordion-button {
  width: 100% !important;
  padding: 12px 4px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  border: none !important;
  margin: 14px 10px 10px 10px !important;
  border-radius: 12px !important;
  color: #696f7b !important;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}
.accordion .menuItem .accordion-button:hover {
  background-color: #081225;
  color: white;
  cursor: pointer;
}

.accordion .dropDown {
  border-radius: 0px 0px 12px 0px !important;
  background: transparent;
}

.accordion .activeMenu.dropDown .accordion-button {
  border-radius: 0px 12px 0px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: #696f7b !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 12px !important;
  box-shadow: none !important;
  padding: 14px 20px;
}

.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.outlet {
  position: absolute !important;
  top: 12vh !important;
  left: 1vh !important;
  right: 0 !important;
}

/* krupa changes */
/* .sidenav-lg::-webkit-scrollbar {
  width: 8px;
}
.sidenav-lg::-webkit-scrollbar-track {
  background: var(--primary-color);
}
.sidenav-lg::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 6px;
}

.sidenav-lg {
  height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding-right: 8px;
  overflow-y: scroll;
  width: 40vh;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  --scrollbarBG: rgb(0, 0, 0);
  --thumbBG: #var(--primary-color);
}
.sidenav-lg hr {
  border-width: 2px;
  color: gray;
  width: 90%;
  margin: auto;
  opacity: 1;
  text-align: center;
}

.sidenav-lg-parent {
  position: relative;
}
/* .outlet {
  position: absolute !important;
  top: 12vh !important;
  left: 1vh !important;
  right: 0 !important;
} */

nav {
  position: fixed;
  left: 40vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  z-index: 1000;
  border: 1px solid #ffffff;
  border-radius: 15px;
}

nav h5 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input.searchInputField.form-control {
  border-radius: 50px;
  background-color: #f0f3f4;
  border: "none";
}

.searchCard {
  padding: 0px 14px 14px !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.searchCard .searchLabel {
  background: linear-gradient(145deg, #000000, #000000) !important;
  width: max-content !important;
  color: var(--white) !important;
  font-weight: 500 !important;
  text-align: center !important;
  margin: auto auto 12px auto !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 2px 8px !important;
  font-size: 13px !important;
}

.session-nav {
  position: fixed;
  left: 0vh;
  right: 0vh;
  top: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  z-index: 1000;
  border: 1px solid #ffffff;
  border-radius: 15px;
}

@media (max-width: 992px) {
  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 50%;
    z-index: 1000;
    flex-direction: column;
    padding: 10% 5% 5% 2%;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: #f0f3f4;
  }

  .mobile-nav li {
    margin-bottom: 15px;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav a.nav-link {
    border: 1px solid black;
    color: 1px solid black;
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .mobile-nav a.nav-link.active {
    background: white;
    border: 1px solid transparent;
    color: black;
    font-weight: 500;
  }

  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin: 5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: black;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #081225 !important;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
}

@media (min-width: 200px) and (max-width: 400px) {
}

@media (min-width: 992px) {
  .mobile-nav {
    display: none;
  }

  .mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg::-webkit-scrollbar {
    width: 8px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: #f0f3f4;
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: #f0f3f4;
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #f0f3f4;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    width: 40vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: var(--primary-color);
    border-radius: 10px;
    border: 6px solid #d6dde1;
  }

  .sidenav-lg hr {
    border-width: 2px;
    color: black;
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative;
  }

  .outletDiv {
    position: absolute;
    left: 41vh;
    right: 2vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    left: 1vh !important;
    position: absolute !important;
    right: 1vh !important;
    top: 0 !important;
  }
  nav {
    left: 0;
    right: 0;
  }
}

/* .outlet{
    position: absolute !important;
    top: 8vh !important;
    left: 12vh !important;
    right: 0vh !important;
    display: flex !important;
    justify-content: center !important
  }
}

/* modal css */
.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.modal .modal-content .modal-header {
  background: var(--FoxYellow);
  padding: 10px 16px;
  font-weight: 600;
}

.modal .modal-content .modal-header h5 {
  margin: 0px;
  font-weight: 600;
  color: var(--FoxBlack);
}

.modal label {
  font-size: 15px !important;
  color: #575757;
  font-weight: 600 !important;
  margin: 0px 0px 4px !important;
}

.modal .modal-footer {
  background: #ebebeb !important;
}

/* for table design */
.outer-wrapper {
  padding: 10px;
  background: #ffffff !important;
  max-width: 95%;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  width: 100%;
  margin: 10px auto;
}

.table-wrapper {
  height: fit-content;
  margin: 5px 3px;
  /* max-height: 66.4vh; */
  /* overflow-x: scroll;
    overflow-y: scroll; */
  overflow: auto;
  padding-bottom: 20px;
}

.table-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
}
.outer-wrapper table th {
  background: #bdbdbd !important;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  position: sticky !important;
  text-align: left !important;
  top: 0 !important;
  z-index: 1 !important;
}
.outer-wrapper table td {
  font-size: 12px !important;
  padding-left: 10px !important;
  text-align: left !important;
  color: #7e828b !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}
.outer-wrapper table td,
.outer-wrapper table th {
  padding: 6px 10px;
}

.tabledata {
  overflow-x: auto;
}

/* kbc style design */

.kbc .question {
  clip-path: polygon(
    8.8% 0%,
    91.6% 0%,
    100% 50%,
    91.8% 100%,
    8.5% 100.7%,
    0% 50%
  );
  padding: 20px 10px;
  text-align: center;
  background: #081225;
}

.kbc .question h5 {
  width: 75%;
  margin: auto;
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 16px;
}

.kbc .answer {
  clip-path: polygon(
    8.8% 0%,
    91.6% 0%,
    100% 50%,
    91.8% 100%,
    8.5% 100.7%,
    0% 50%
  );
  padding: 20px 10px;
  text-align: center;
  background: #081225;
  color: #ffffff;
  transition: 0.3s;
}

.kbc .answer h5 {
  margin: auto;
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 14px;
}

.kbc .answer:hover {
  background: #213d70;
  transform: scale(1.05);
}

/* design of timer */
.timerContainer {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: white;
  border: 5px solid #081225;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #081225;
  position: relative;
}
.timerContainer p {
  margin: 0;
}
.timerContainer span {
  font-size: 15px;
}
.arrowLeft.disabled {
  color: gray; /* Set your desired color for the disabled state */
}

/* for correct incorrect css */
.answer {
  border: 1px solid #ccc !important;
}

.correct {
  /* Style for correct option */
  background-color: green !important;
  color: white !important;
}

.incorrect {
  /* Style for incorrect option */
  background-color: red !important;
  color: white !important;
}

.requiredfield {
  color: red;
}

.liveSession {
  /* width: 90% !important; */
  margin-top: 3% !important;
}

.liveStudent {
  width: 15%;
  height: 40px;
  margin: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width:700px) and (max-width:1024px) {
  .liveStudent {
    width: 30%;
    height: 40px;
    margin: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width:300px) and (max-width:700px) {
  .liveStudent {
    width: 47%;
    margin: 4px;
  }
}

/* ---------------- Class Info ------------------ */

.Dashboard .nav {
  width: max-content;
  border: none;
  border-radius: 8px;
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.Dashboard .nav .nav-link {
  border-radius: 8px;
  padding: 5px 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000 !important;
}

.Dashboard .nav .nav-link.active {
  background: #000000 !important;
  color: #ffffff !important;
  border: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
